import { createEnv } from "@t3-oss/env-nextjs"
import { env as envRuntime } from "next-runtime-env"
import { z } from "zod"

export const env = createEnv({
  server: {
    NODE_ENV: z.enum(["development", "production", "test"]).default("development"),
    PLAYBOARD_API_URL: z.string(),
    BYTEARK_STREAM_API_KEY: z.string(),
    BYTEARK_STREAM_FORM_ID: z.string(),
    BYTEARK_STREAM_FORM_SECRET: z.string(),
    BYTEARK_STREAM_API_URL: z.string(),
    BYTEARK_STREAM_AUTH_API_URL: z.string(),
    GOOGLE_ANALYTICS_ID: z.string().optional(),
  },
  client: {
    NEXT_PUBLIC_BASE_URL: z.string(),
    NEXT_PUBLIC_API_URL: z.string(),
    NEXT_PUBLIC_PLAYBOARD_API_URL: z.string(),
    NEXT_PUBLIC_LINE_LIFF_ID: z.string(),
    NEXT_PUBLIC_BYTEARK_STREAM_FORM_ID: z.string(),
    NEXT_PUBLIC_BYTEARK_STREAM_FORM_SECRET: z.string(),
    NEXT_PUBLIC_SUPABASE_URL: z.string(),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string(),
    NEXT_PUBLIC_BRAND_URL: z.string(),
    NEXT_PUBLIC_FACEBOOK_URL: z.string(),
    NEXT_PUBLIC_TIKTOK_URL: z.string(),
    NEXT_PUBLIC_LINE_URL: z.string(),
    NEXT_PUBLIC_YOUTUBE_URL: z.string(),
    NEXT_PUBLIC_INSTAGRAM_URL: z.string(),
    NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_PROJECT_ID: z.string(),
    NEXT_PUBLIC_POSTHOG_KEY: z.string().optional(),
    NEXT_PUBLIC_POSTHOG_UI_HOST: z.string().optional(),
    NEXT_PUBLIC_POSTHOG_API_HOST: z.string().optional(),
    NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
    NEXT_PUBLIC_SENTRY_ENV: z.string().optional(),
    NEXT_PUBLIC_SKIP_TERM_OF_SERVICE: z
      .string()
      .transform((s) => s !== "false" && s !== "0")
      .default("false"),
    NEXT_PUBLIC_USER_SESSION_KEY: z.string(),
    NEXT_PUBLIC_MEMBER_SESSION_KEY: z.string(),
    NEXT_PUBLIC_PLAYBOARD_LINE_OA_URL: z.string().optional(),
    NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_DASHBOARD_ID: z.string(),
    NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_SUPERSET_DOMAIN: z.string(),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    PLAYBOARD_API_URL: process.env.PLAYBOARD_API_URL,
    BYTEARK_STREAM_API_KEY: process.env.BYTEARK_STREAM_API_KEY,
    BYTEARK_STREAM_FORM_ID: process.env.BYTEARK_STREAM_FORM_ID,
    BYTEARK_STREAM_FORM_SECRET: process.env.BYTEARK_STREAM_FORM_SECRET,
    BYTEARK_STREAM_API_URL: process.env.BYTEARK_STREAM_API_URL,
    BYTEARK_STREAM_AUTH_API_URL: process.env.BYTEARK_STREAM_AUTH_API_URL,
    GOOGLE_ANALYTICS_ID: process.env.GOOGLE_ANALYTICS_ID,

    NEXT_PUBLIC_BASE_URL: envRuntime("NEXT_PUBLIC_BASE_URL"),
    NEXT_PUBLIC_API_URL: envRuntime("NEXT_PUBLIC_API_URL"),
    NEXT_PUBLIC_PLAYBOARD_API_URL: envRuntime("NEXT_PUBLIC_PLAYBOARD_API_URL"),
    NEXT_PUBLIC_LINE_LIFF_ID: envRuntime("NEXT_PUBLIC_LINE_LIFF_ID"),
    NEXT_PUBLIC_BYTEARK_STREAM_FORM_ID: envRuntime("NEXT_PUBLIC_BYTEARK_STREAM_FORM_ID"),
    NEXT_PUBLIC_BYTEARK_STREAM_FORM_SECRET: envRuntime("NEXT_PUBLIC_BYTEARK_STREAM_FORM_SECRET"),
    NEXT_PUBLIC_SUPABASE_URL: envRuntime("NEXT_PUBLIC_SUPABASE_URL"),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: envRuntime("NEXT_PUBLIC_SUPABASE_ANON_KEY"),
    NEXT_PUBLIC_BRAND_URL: envRuntime("NEXT_PUBLIC_BRAND_URL"),
    NEXT_PUBLIC_FACEBOOK_URL: envRuntime("NEXT_PUBLIC_FACEBOOK_URL"),
    NEXT_PUBLIC_TIKTOK_URL: envRuntime("NEXT_PUBLIC_TIKTOK_URL"),
    NEXT_PUBLIC_LINE_URL: envRuntime("NEXT_PUBLIC_LINE_URL"),
    NEXT_PUBLIC_YOUTUBE_URL: envRuntime("NEXT_PUBLIC_YOUTUBE_URL"),
    NEXT_PUBLIC_INSTAGRAM_URL: envRuntime("NEXT_PUBLIC_INSTAGRAM_URL"),
    NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_PROJECT_ID: envRuntime("NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_PROJECT_ID"),
    NEXT_PUBLIC_POSTHOG_KEY: envRuntime("NEXT_PUBLIC_POSTHOG_KEY"),
    NEXT_PUBLIC_POSTHOG_UI_HOST: envRuntime("NEXT_PUBLIC_POSTHOG_UI_HOST"),
    NEXT_PUBLIC_POSTHOG_API_HOST: envRuntime("NEXT_PUBLIC_POSTHOG_API_HOST"),
    NEXT_PUBLIC_SENTRY_DSN: envRuntime("NEXT_PUBLIC_SENTRY_DSN"),
    NEXT_PUBLIC_SENTRY_ENV: envRuntime("NEXT_PUBLIC_SENTRY_ENV"),
    NEXT_PUBLIC_SKIP_TERM_OF_SERVICE: envRuntime("NEXT_PUBLIC_SKIP_TERM_OF_SERVICE"),
    NEXT_PUBLIC_USER_SESSION_KEY: envRuntime("NEXT_PUBLIC_USER_SESSION_KEY"),
    NEXT_PUBLIC_MEMBER_SESSION_KEY: envRuntime("NEXT_PUBLIC_MEMBER_SESSION_KEY"),
    NEXT_PUBLIC_PLAYBOARD_LINE_OA_URL: envRuntime("NEXT_PUBLIC_PLAYBOARD_LINE_OA_URL"),
    NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_DASHBOARD_ID: envRuntime("NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_DASHBOARD_ID"),
    NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_SUPERSET_DOMAIN: envRuntime("NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_SUPERSET_DOMAIN"),
  },
  skipValidation: true,
  emptyStringAsUndefined: true,
})
